import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { kebabCase } from 'utils/kebabCase';
import './postTile.scss';

const PostTile = ({ className, imageUrl, title, description }) => {
  return (
    <Link className={className} to={kebabCase(title)}>
        <div className="card post-tile">
            <div className="card-image">
                <figure className="image post-tile__image is-3by2">
                    <img src={imageUrl} alt={title} />
                </figure>
            </div>

            <div className="pt-3 pb-3 pl-3 pr-3">
                <p className="title is-4">{title}</p>
                <p className="subtitle">{description}</p>
            </div>
        </div>
    </Link>
  );
};

PostTile.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default PostTile;
