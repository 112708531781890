const map = {
  'a' : 'ą',
  'e' : 'ę',
  'z' : 'ż|ź',
  'o' : 'ó|Ó',
  'c' : 'ć',
  'n' : 'ń',
  'l': 'ł'
};

module.exports = {
  kebabCase: function(stringToConvert) {
    stringToConvert = stringToConvert.toLowerCase();
    for (let pattern in map) {
      stringToConvert = stringToConvert.replace(new RegExp(map[pattern], 'g'), pattern);
    }

    return stringToConvert.split(' ').join('-').replace('---', '-');
  },
};
