import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tags = ({tags, onClick}) => {

    const tagClassNames = selected => {
        return classNames({
            'tag is-info is-medium': true,
            'is-light': !selected
        });
    };

    return (
        <div className="tags">
            {
                tags.map(({value, selected}, index) => (
                    <div key={value} className={tagClassNames(selected)} onClick={() => onClick(index)}>
                        {value}

                        {selected ? <i className="delete is-small" /> : null}
                    </div>
                ))
            }
        </div>
    );
};

Tags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired
    })),
    onClick: PropTypes.func.isRequired
};

export default Tags;
