import React, {useMemo, useState} from 'react';
import { graphql } from 'gatsby';
import {Helmet} from 'react-helmet';
import PostTiles from 'components/PostTiles/PostTiles';
import Tags from '../components/Tags/Tags';

const Blog = props => {
  const {
    data: {
      allContentfulBlogPost: { nodes },
      allContentfulTag: {nodes: tagsNodes}
    },
  } = props;

  const setTagsActive = () => {
      tagsNodes.forEach(tag => {
          tag.selected = true;
      });
      return tagsNodes;
  };

  const [tags, setTags] = useState(setTagsActive());

  const selectedTags = tags.filter(({selected}) => selected).map(({value}) => value);

  const onTagClick = tagIndex => {
      const tag = {...tags[tagIndex], selected: !tags[tagIndex].selected};
      setTags([...tags.slice(0, tagIndex), tag, ...tags.slice(tagIndex +1)]);
  };

    const includesOneOrMoreTags = (postTags) => {
        let includes = false;

        if(postTags.length) {
            postTags.forEach(tag => {
                if(selectedTags.includes(tag)) {
                    includes = true;
                }
            });
        }
        return includes;
    };

  const filterPosts = () => {
      return selectedTags.length ? nodes.filter(node => {
         return node.tags ? includesOneOrMoreTags(node.tags.filter(({value}) => value).map(({value}) => value)) : true;
      }) : nodes;
  };

  const selectedPosts = useMemo(filterPosts, [tags, nodes]);

  return (
      <>
        <Helmet title="Blog - Marcin Madej">
            <meta name="Description" content="blog" />
        </Helmet>

        <Tags tags={tags} onClick={onTagClick} />

        <PostTiles nodes={selectedPosts} />
      </>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allContentfulTag {
        nodes {
            value
        }
    }
    allContentfulBlogPost(sort: {fields: createdAt, order: DESC}) {
      nodes {
        title
        description
        image {
          file {
            url
          }
        }
        tags {
            value
        }
      }
    }
  }
`;
