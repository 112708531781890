import React from 'react';
import PostTile from 'components/PostTile/PostTile';
import { kebabCase } from 'utils/kebabCase';

const PostTiles = ({ nodes }) => {
  return (
    <div className="columns is-multiline">
      {nodes.map(({ title, description, image }) => (
        <PostTile
            className="column is-full-mobile is-half-tablet is-one-third-desktop is-flex"
            title={title}
            description={description}
            imageUrl={image.file.url}
            slug={kebabCase(title)}
            key={title}
        />
      ))}
    </div>
  );
};

export default PostTiles;
